import React, { useEffect, useState } from 'react'
import NavBar from "../NavBar/NavBar";

import CallList from "./CallList/CallList";
import CallHistory from "./CallHistory/CallHistory";
import { ThemeColor } from "../../Config/Config";

const Calls = () => {

  const [state, setState] = useState({
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    mainVisible: false,
    isRTL: Boolean(localStorage.getItem("rtl")),
  })

  useEffect(() => {
    let classList = document.body.classList;
    if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
      document.body.classList.add("dark-skin");
    } else if (classList.length && state.themeColor === ThemeColor.Light) {
      document.body.classList.remove("dark-skin");
    }
    if (state.isRTL) document.body.setAttribute("dir", "rtl");
    else document.body.removeAttribute("dir");
  }, [])

  const handleMainVisible = () => {
    setState({ mainVisible: true });
  };

  const handleHideMain = () => {
    setState({ mainVisible: false });
  };

  return (
    <div className="chats-tab-open h-100">
      <div className={"main-layout h-100"}>
        <NavBar activeMenu="calls" />

        {/* Call list */}
        <CallList setMainVisible={handleMainVisible} />

        {/* Chat history with  */}
        <CallHistory
          showMain={state.mainVisible}
          hideMain={handleHideMain}
        />

        <div className="backdrop"></div>
      </div>
    </div>
  )
}

export default Calls
