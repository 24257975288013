import React, { createContext } from 'react';
import { io } from 'socket.io-client';
import { USER_DATA_LOCAL_STORAGE_KEY } from '../constant';
import { useJwt } from 'react-jwt';

const socket = io(process.env.REACT_APP_API_BASE_URL);
const SocketContext = createContext(socket);

const userDetails = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
const userId = userDetails?.userId;
socket.on('connect', () => {
  socket.emit("userLogin", {userId:userId})
  console.log('connected to socket', socket?.id, userDetails, userId);
});

const SocketProvider = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
export { SocketContext, SocketProvider };