// HTTP statuses
import { routes } from './routes';

export const UNAUTHORIZED_STATUS = 401;
export const SUCCESS_STATUS = 200;
export const NO_CONTENT_STATUS = 204;

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

// Local Storage keys
export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'accessToken';
export const ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY = 'expiration_key';
export const ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY = 'login_key';
export const USER_DATA_LOCAL_STORAGE_KEY = 'user';
export const ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY = 360;

export { routes };
