import React from "react";
// import { Route, Routes } from "react-router-dom";

import EmptyChat from "./EmptyChat";
import UserChat from "./UserChat";
import GroupChat from "./GroupChat";

// Chat History with different Routing
const ChatHistory = (props) => {

  console.log("ChatHistory", props);

  return (
    <>
      {props.type === "user" ? <UserChat /> : ''}
      {props.type === "group" ? <GroupChat /> : ''}
      {props.type === "" ? <EmptyChat /> : ''}
      {/* <UserChat /> */}
      {/* <Routes>
      <Route path="/Chats" component={EmptyChat} exact />
      <Route path="/Chats/user1" component={UserChat} exact />
      <Route path="/Chats/group1" component={GroupChat} exact />
    </Routes> */}
    </>
  );
}
export default ChatHistory;
