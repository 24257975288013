import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import SignIn from "../components/SignIn/SignIn";
import SignUp from "../components/SignUp/SignUp";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import Home from "../components/Home/Home";
import Chats from "../components/Chats/Chats";
import Calls from "../components/Calls/Calls";
import Friends from "../components/Friends/Friends";
import Account from "../components/Account/Account";
import Documentation from "../components/Documentation/Documentation";

// Route component to manage app routes
class Routing extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Routes>
        <Route path="/Home" element={<Home />} exact />
        <Route path="/SignIn" element={<SignIn />} exact />
        <Route path="/SignUp" element={<SignUp />} exact />
        <Route path="/reset-password" element={<ResetPassword />} exact />
        <Route path="/Chats" element={<Chats />} />
        <Route path="/Calls" element={<Calls />} />
        <Route path="/Friends" element={<Friends />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/Documentation" element={<Documentation />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default Routing;
