import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: [],
};

export const messagesSlice = createSlice({
  name: 'messagesList',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearAllMessages: (state) => {
      state.messages = [];
    },
  },
});

export const { addMessage, clearAllMessages } =
  messagesSlice.actions;

export const getAllMessages = (state) =>
  state.messagesList.messages;

export default messagesSlice.reducer;
