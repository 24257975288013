import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import CreateGroup from "../Modals/CreateGroup/CreateGroup";
import InviteOthers from "../Modals/InviteOthers/InviteOthers";
import Notifications from "../Modals/Notifications/Notifications";
import StartConversation from "../Modals/StartConversation/StartConversation";

import { ReactComponent as NotificationsSvg } from "../../assets/media/icons/notifications.svg";
import { ReactComponent as VerticalOptionDots } from "../../assets/media/icons/verticaloptiondots.svg";

const ChatAction = () => {

  const [state, setState] = useState({
    showNotification: false,
    showNewChat: false,
    showNewGroup: false,
    showInviteOthers: false,
  })

  const toggleNotification = () => {
    setState({ showNotification: !state.showNotification });
  };

  const toggleNewChat = () => {
    setState({ showNewChat: !state.showNewChat });
  };

  const toggleCreateGroup = () => {
    setState({ showNewGroup: !state.showNewGroup });
  };

  const toggleInviteOthers = () => {
    setState({ showInviteOthers: !state.showInviteOthers });
  };

  return (
    <>
      <ul className="nav flex-nowrap">
        <li className="nav-item list-inline-item mr-1">
          <Link
            className="nav-link text-muted px-1"
            title="Notifications"
            role="button"
            data-toggle="modal"
            data-target="#notificationModal"
            onClick={toggleNotification}
            to="#"
          >
            <NotificationsSvg />
          </Link>
        </li>

        <li className="nav-item list-inline-item mr-0">
          <Dropdown>
            <Dropdown.Toggle
              className="text-muted hw-20 mt-2"
              as={VerticalOptionDots}
            ></Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                onClick={toggleNewChat}
                to="#"
              >
                New Chat
              </Link>

              <Link
                className="dropdown-item"
                onClick={toggleCreateGroup}
                to="#"
              >
                Create Group
              </Link>

              <Link
                className="dropdown-item"
                onClick={toggleInviteOthers}
                to="#"
              >
                Invite Others
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>

      {/* Render modal based on button click */}
      {state.showNotification ? (
        <Notifications hideNotification={toggleNotification} />
      ) : null}
      {state.showNewChat ? (
        <StartConversation hideNewChat={toggleNewChat} />
      ) : null}
      {state.showNewGroup ? (
        <CreateGroup hideNewGroup={toggleCreateGroup} />
      ) : null}
      {state.showInviteOthers ? (
        <InviteOthers hideInviteOthers={toggleInviteOthers} />
      ) : null}
    </>
  )
}

export default ChatAction
