import { configureStore } from '@reduxjs/toolkit';
import userListSlice from './userListSlice';
import messagesSlice from './messagesSlice';


export const store = configureStore({
  reducer: {
    usersList: userListSlice,
    messagesList: messagesSlice,
  },
});

