import React, { useContext } from "react";
import { Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ThemeColor } from "../../Config/Config";
import { Button } from "react-bootstrap";
import { POST } from "../../services/ApiService";
import { Field, Form, Formik } from "formik";
import { SignInInitialValues, SignInValidation } from "./constant";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { login } from "../../api/auth";
import { useMutation } from "@tanstack/react-query";
import { ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY, ACCESS_TOKEN_LOCAL_STORAGE_KEY, ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, USER_DATA_LOCAL_STORAGE_KEY, routes } from "../../constant";
import moment from "moment";
import { catchBlock } from "../../utils";
import { SocketContext } from "../../contexts/socketContext";

// SignIn component
const SignIn = () => {
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const [themeColor, setThemeColor] = useState(Boolean(localStorage.getItem("theme"))
    ? parseInt(localStorage.getItem("theme"))
    : ThemeColor.Light);
  const [isRemember, setIsRemember] = useState(false);
  const [isRTL, setIsRTL] = useState(Boolean(localStorage.getItem("rtl")));

  // Load selected theme
  // componentDidMount() {
  //   let classList = document.body.classList;
  //   if (classList.length === 0 && themeColor === ThemeColor.Dark) {
  //     document.body.classList.add("dark-skin");
  //   } else if (classList.length && themeColor === ThemeColor.Light) {
  //     document.body.classList.remove("dark-skin");
  //   }
  //   if (isRTL) document.body.setAttribute("dir", "rtl");
  //   else document.body.removeAttribute("dir");
  // }

  const toggleIsremember = () => {
    // this.setState({ isRemember: !this.state.isRemember });
    setIsRemember(!isRemember);
  };

  const { mutate: loginPostMutate, isLoading } = useMutation({mutationFn:login, ...{
    onSuccess: (data) => {
      console.log(data);
      // toast.success(data.message, TOAST_CONFIG);
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, data.data.token);
      localStorage.setItem(
        USER_DATA_LOCAL_STORAGE_KEY,
        JSON.stringify({
          name: `${data.data.user.first_name} ${data.data.user.last_name}`,
          email: data.data.user.email,
          userId: data.data.user._id,
        }),
      );
      localStorage.setItem(ACCESS_TOKEN_LOGIN_TIME_LOCAL_STORAGE_KEY, moment());
      localStorage.setItem(
        ACCESS_TOKEN_EXPIRATION_TIME_LOCAL_STORAGE_KEY,
        moment().add(ACCESS_TOKEN_LOGIN_EXPIRE_MINUTES_LOCAL_STORAGE_KEY, 'minutes'),
      );
      socket.emit("userLogin", {userId:data.data.user._id});
      navigate(routes.chats);
    },
    onError: (error) => {
      catchBlock(error);
    },
  }});
  const LoginPost = useCallback(
    (values) => {
      const requestValues = {
        email: values.email,
        password: values.password,
      };
      loginPostMutate(requestValues);
    },
    [loginPostMutate],
  );

  return (
    <div className={"main-layout card-bg-1"}>
      <div className="container d-flex flex-column">
        <div className="row no-gutters text-center align-items-center justify-content-center min-vh-100">
          <div className="col-12 col-md-6 col-lg-5 col-xl-4">
            <h1 className="font-weight-bold">Sign in</h1>
            <p className="text-dark mb-3">
              We are Different, We Make You Different.
            </p>
            <Formik initialValues={SignInInitialValues} validationSchema={SignInValidation} onSubmit={LoginPost}>
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <Form className="mb-3" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="sr-only">Email Address</label>
                    <Field
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      className="form-control form-control-md"
                      placeholder="Enter your email"
                    />
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Password</label>
                    <Field
                    name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className="form-control form-control-md"
                      placeholder="Enter your password"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between">
                    <div
                      className="custom-control custom-checkbox"
                      onClick={toggleIsremember}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={isRemember}
                        onChange={toggleIsremember}
                      ></input>
                      <label className="custom-control-label text-muted font-size-sm">
                        Remember me
                      </label>
                    </div>
                    <Link className="font-size-sm" to="/reset-password">
                      Reset password
                    </Link>
                  </div>
                  {/* <Link
                  to="/Chats"
                  className="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold"
                >
                  Sign in
                </Link> */}
                  <Button type="submit" className="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold">Sign In</Button>
                </Form>
              )}
            </Formik>
            <p>
              Don't have an account?{" "}
              <Link className="font-weight-semibold" to="/SignUp">
                Sign up
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
