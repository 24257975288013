import React, { useEffect, useState } from 'react';

import { ThemeColor } from "../../Config/Config";
import NavBar from "../NavBar/NavBar";
import FriendList from "./FriendList/FriendList";
import FriendProfile from "./FriendProfile/FriendProfile";

const Friends = () => {

  const [state, setState] = useState({
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    mainVisible: false,
    isRTL: Boolean(localStorage.getItem("rtl")),
  })

  useEffect(()=>{
    let classList = document.body.classList;
    if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
      document.body.classList.add("dark-skin");
    } else if (classList.length && state.themeColor === ThemeColor.Light) {
      document.body.classList.remove("dark-skin");
    }
    if (state.isRTL) document.body.setAttribute("dir", "rtl");
    else document.body.removeAttribute("dir");
  },[])

  const handleMainVisible = () => {
    setState({ mainVisible: true });
  };

  const handleHideMain = () => {
    setState({ mainVisible: false });
  };

  return (
    <div className="chats-tab-open h-100">
    <div className={"main-layout h-100"}>
      <NavBar activeMenu="friends" />

      <FriendList setMainVisible={handleMainVisible} />

      <FriendProfile
        showMain={state.mainVisible}
        hideMain={handleHideMain}
      />

      <div className="backdrop"></div>
    </div>
  </div>
  )
}

export default Friends
