import axios from 'axios';
import { NO_CONTENT_STATUS, SUCCESS_STATUS } from '../constant';
import { checkUserAuth } from '../utils';

export const baseURL = process.env.REACT_APP_API_BASE_URL;

const Api = axios.create({
  baseURL,
});

Api.defaults.headers.post['Content-Type'] = 'application/json';

Api.interceptors.request.use((req) => {
  const token = checkUserAuth();

  if (token) {
    req.headers.Authorization = `${token}`;
  }

  return req;
});

Api.interceptors.response.use(
  (response) => {
    const isSuccessStatus = response.status === SUCCESS_STATUS || response.status === NO_CONTENT_STATUS;

    if (!isSuccessStatus) {
      return Promise.reject(response.data.message);
    }

    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  },
);

export default Api;
