import React, { Component } from "react";
import Routing from "./Routing/Routing";
import { ThemeColor } from "./Config/Config";
import { GuestRoute, PrivateRoute } from './routes';

import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Home from "./components/Home/Home";
import Chats from "./components/Chats/Chats";
import Calls from "./components/Calls/Calls";
import Friends from "./components/Friends/Friends";
import Account from "./components/Account/Account";
import Documentation from "./components/Documentation/Documentation";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { routes } from "./constant";
// import EmptyChat from "./components/Chats/ChatHistory/EmptyChat";
// import UserChat from "./components/Chats/ChatHistory/UserChat";
// import GroupChat from "./components/Chats/ChatHistory/GroupChat";

const App = () => {
  // state = {
  //   themeColor: Boolean(localStorage.getItem("theme"))
  //     ? parseInt(localStorage.getItem("theme"))
  //     : ThemeColor.Light,
  // };

  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path={routes.home} element={<Home />} exact />
          <Route path={routes.signIn} element={<GuestRoute Component={SignIn} />} exact />
          <Route path={routes.signUp} element={<GuestRoute Component={SignUp} />} exact />
          <Route path={routes.reset} element={<GuestRoute Component={ResetPassword} />} exact />

          <Route path={routes.chats} element={<PrivateRoute Component={Chats} />} exact />
          <Route path={`${routes.chats}/user/:userId`} element={<PrivateRoute Component={Chats} />} exact />
          <Route path={`${routes.chats}/group/:groupId`} element={<PrivateRoute Component={Chats} />} exact />
          {/* <Route path={`${routes.chats}`} element={<PrivateRoute Component={Chats} />} >
          <Route path="user/:userId" element={<GuestRoute Component={Chats} />} />
          <Route path="group/:groupId" element={<GuestRoute Component={Chats} />} />
        </Route> */}
          <Route path={routes.calls} element={<GuestRoute Component={Calls} />} />
          <Route path={routes.friends} element={<GuestRoute Component={Friends} />} />
          <Route path={routes.account} element={<GuestRoute Component={Account} />} />
          <Route path={routes.documentation} element={<Documentation />} />
        </Routes>
      </BrowserRouter>
      <Outlet />
    </>
  );
}

export default App;
