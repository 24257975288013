import React from 'react';
import { Navigate } from 'react-router-dom';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, routes } from '../constant';
// eslint-disable-next-line import/order
import PropTypes from 'prop-types';

const PrivateRoute = ({ Component }) => {
  const loginUser = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  let isLogin = false;
  if (loginUser !== null) {
    isLogin = true;
  }
  return isLogin ? (
      <Component />
  ) : (
    <Navigate to={routes.signIn} />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.any.isRequired,
};

export default PrivateRoute;
