import axios, { Method } from 'axios';

axios.defaults.baseURL = process.env.API_BASE_URL;

/**
 * Global request function for handling all HTTP calls
 * @param method
 * @param url
 * @param options {params: <for query params>, payload: <for data to be sent>'}
 */

const request = (
  method,
  url,
  options = { params: {}, payload: {} }
) => {
  const request = {
    url,
    method,
    params: options.params,
    data: options.payload,
  };

  return new Promise((resolve, reject) => {
    axios
      .request(request)
      // TODO: remove sleeper after all loader simulation is developed
      // .then(sleeper(4000))
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export const POST = (path, payload) => {
  return request('POST', path, {
    payload,
    params: {},
  });
};

export const UPLOAD = (path, payload) => {
  return axios.post(path, payload, {
    onUploadProgress: (ProgressEvent) =>
      (ProgressEvent.loaded / ProgressEvent.total) * 100,
  });
};

export const GET = (path, params = {}) => {
  return request('GET', path, {
    params,
    payload: {},
  });
};

export const GETALL = (path, filters) => {
  return request('GET', path, {
    params: filters,
    payload: {},
  });
};

export const PUT = (path, payload) => {
  return request('PUT', path, {
    payload,
    params: {},
  });
};

export const PATCH = (path, payload) => {
  return request('PATCH', path, {
    payload: payload,
    params: {},
  });
};

export const DELETE = (path) => {
  return request('DELETE', path);
};

axios.interceptors.request.use(async (request) => {
  const headers = {};
  // const session= await getSession();
  const session = false;
  if (session) {
    headers['Authorization'] = `Bearer ${session.user.accessToken}`;
  }
  request.headers = headers;
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      // Router.push('/');
      window.location = "/";
    } else if (error.response?.status === 500) {
      return Promise.reject(error);
    }
    return Promise.reject(error.response?.data);
  }
);
