import React, { useContext, useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";

import ChatList from "./ChatList/ChatList";
import ChatHistory from "./ChatHistory/ChatHistory";
import { ThemeColor } from "../../Config/Config";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, selectUser } from "../../redux/userListSlice";
import { SocketContext } from "../../contexts/socketContext";
import { USER_DATA_LOCAL_STORAGE_KEY } from "../../constant";
import { clearAllMessages } from "../../redux/messagesSlice";

// Chat master component
const Chats = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const userList = useSelector(getAllUsers);
  const socket = useContext(SocketContext);
  const userDetails = JSON.parse(localStorage.getItem(USER_DATA_LOCAL_STORAGE_KEY));
  const userId = userDetails?.userId;

  const [state, setState] = useState({
    mainVisible: false,
    themeColor: Boolean(localStorage.getItem("theme"))
      ? parseInt(localStorage.getItem("theme"))
      : ThemeColor.Light,
    isRTL: Boolean(localStorage.getItem("rtl")),
  });

  // Load selected theme
  // componentDidMount() {
  //   let classList = document.body.classList;
  //   if (classList.length === 0 && this.state.themeColor === ThemeColor.Dark) {
  //     document.body.classList.add("dark-skin");
  //   } else if (classList.length && this.state.themeColor === ThemeColor.Light) {
  //     document.body.classList.remove("dark-skin");
  //   }
  //   if (this.state.isRTL) document.body.setAttribute("dir", "rtl");
  //   else document.body.removeAttribute("dir");
  // }

  const handleChatClick = (id) => {
    const foundedUser = userList.find(u=>u._id===id);
    socket.emit('userChange', { "friendId":id, "senderId":userId });
    dispatch(selectUser(foundedUser));
    dispatch(clearAllMessages());
    setState({state, ...{ mainVisible: true }});
  };

  // const handleBackToList = () => {
  //   this.setState({ mainVisible: false });
  // };

  useEffect(() => {
    if(params?.userId && userList.length){
      const foundedUser = userList.find(u=>u._id===params?.userId);
      socket.emit('userChange', { "friendId":params?.userId, "senderId":userId });
      dispatch(selectUser(foundedUser));
      dispatch(clearAllMessages());
      setState({state, ...{ mainVisible: true }});
    }
  }, [userList, params]);
  
  return (
    <div className="chats-tab-open h-100">
      <div className={"main-layout h-100"}>
        <NavBar activeMenu="chat" />

        <ChatList handleChatClick={handleChatClick} />
        
        {params?.userId?<ChatHistory type="user" mainVisible={state.mainVisible} /* BackToListClicked={handleBackToList} *//>:''}
        {params?.groupId?<ChatHistory type="group" mainVisible={state.mainVisible} /* BackToListClicked={handleBackToList} *//>:''}
        {!params?.groupId && !params?.userId?<ChatHistory type="" mainVisible={state.mainVisible} /* BackToListClicked={handleBackToList} *//>:''}
        {/* <ChatHistory
          mainVisible={state.mainVisible}
          BackToListClicked={handleBackToList}
        /> */}
        <div className="backdrop"></div>
      </div>
    </div>
  );
}

export default Chats
