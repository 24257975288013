export const routes = {
  home: '/Home',
  signIn: '/SignIn',
  signUp: '/SignUp',
  reset: '/reset',
  chats: '/Chats',
  calls: '/Calls',
  friends: '/Friends',
  account: '/Account',
  documentation: '/Documentation',
};
