import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  users: [],
  selectedUser: {},
};

export const userListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    selectUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    addUsers: (state, action) => {
      state.users.push(...action.payload);
    }
  },
});

export const { selectUser, addUsers } =
  userListSlice.actions;

export const getSelectedUser = (state) => state.usersList.selectedUser;

export const getAllUsers = (state) =>
  state.usersList.users;

export default userListSlice.reducer;
